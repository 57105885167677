<template>
  <div class="bg-gray-100 px-6">
    <div class="bg-white rounded-lg shadow-md mb-2 flex lg:flex-row flex-col justify-between items-center">
        <div class="px-12 py-6">
            <h1 
            class="text-blue-700 text-2xl sm:text-3xl 2xl:text-4xl font-normal text-left">
              <faIcon
              icon="users"
              type="fas"
              class="text-green-500 text-3xl"
              >
              </faIcon>
              Lista de usuarios
            </h1>
            <p class="pt-1 text-md sm:text-lg text-gray-600 text-left">Aquí puedes administrar las cuentas de usuario del sistema.</p>
        </div>
    </div>
    <ListTable
      :columns="fields"
      :filters="filters"
      rowsGetter="user/users"
      :buttons="buttons"
      :managementButtons="true"
      :useStorage="true"
      :columnFilters="true"
      @editar-usuario="editar"
      @borrar-usuario="showUserModal"
      @activar-usuario="showUserModal"
      @filter-input="setFilters"
      @dates-input="setDates"
    >
    <template v-slot:role="data">
      <p class="text-gray-800 break-words capitalize">
        {{ data.value[0]}}
      </p>
    </template>

    <template v-slot:isActive="data">
      <div :class="`px-3 py-1 inline-flex text-sm leading-5 font-semibold rounded-full ${data.value ? 'bg-green-300 text-green-800' : 'bg-gray-200 text-gray-500'}`">
        {{ data.value ? 'Activo' : 'Desactivado' }}
      </div>
    </template>

    <template v-slot:emailVerified="data">
      <div :class="`px-3 py-1 inline-flex text-center text-sm leading-5 font-semibold rounded-full ${data.value ? 'bg-green-300 text-green-800' : 'bg-gray-200 text-gray-500'}`">
        {{ data.value ? 'Verificado' : 'No verificado'}}
      </div>
    </template>

    <template v-slot:created_at="data">
      <p class="text-gray-800 break-words font-medium text-base">
        {{ data.value }}
      </p>
    </template>

    <template v-slot:sucursal%nombre="data">
      <p class="text-gray-800 break-words font-medium text-base">
        {{ data.value }}
      </p>
    </template>

    </ListTable>
    <Pagination
      path="listar"
      :meta="meta"
      :links="links"
      action="user/paginateUsers"
      v-if="meta && meta.last_page > 1"
    />
    <Modal 
    v-if="showModal"
    titulo="¡Un momento!"
    :parrafo="`Vas a ${userInModal.isActive ? 'desactivar' : 'activar'} la cuenta de ${userInModal.name}.`"
    pregunta="¿Deseas continuar?"
    @accion="() => {
      if(userInModal.isActive) {
        borrar()
      }else {
        activar()
      }              
    }"
    @cerrar="showModal = false"
    >
    </Modal>
  </div>
</template>
<script>

import ListTable from "@/components/ListTable"
import Pagination from "@/components/Pagination"
import Modal from "@/components/Modal"
import { mapGetters } from "vuex"
import store from "@/store/index"
import UserService from "@/services/UserService"

export default {
  name: "UsersList",
  setup() {
    let sucursales = store.getters['sucursal/sucursales']
    sucursales = sucursales.map(sucursal => {
      return {text: sucursal.suc_nombre,value: sucursal.suc_id}
    })
    let initials = store.getters['user/filters']
    let dates = store.getters['user/dates']

    return {
      fields: {
        "name": "Nombre",
        "email": "E-mail",
        "sucursal%nombre": "Sucursal",
        "role": "Rol",
        "isActive": "Estado",
        "emailVerified": "Verificado",
        "created_at": "Registro"
      },
      filters: [
        {
          "placeholder": "Buscar por nombre",
          "type": "input",
          "field": "usr_nombre",
          "initial": initials && initials.usr_nombre ? initials.usr_nombre.value : ""
        },
        {
          "placeholder": "Buscar por correo",
          "field": "email",
          "initial": initials && initials.email ? initials.email.value : ""
        },
        {
          "placeholder": "Por sucursal",
          "type": "select",
          "field": "usr_sucursal_id",
          "options": sucursales,
          "initial": initials && initials.usr_sucursal_id ? initials.usr_sucursal_id.value : ""
        },
        {
          "placeholder": "Por rol",
          "type": "select",
          "field": "role",
          "fieldType": "single",
          "options": [{value:"admin",text:"Admin"},{value:"estandar",text:"Estandar"},{value:"montaje",text:"Montaje"}],
          "initial": initials && initials.role ? initials.role.value : ""
          
        },
        {
          "placeholder": "Por estado",
          "type": "select",
          "field": "usr_estado",
          "options": [{value: 1, text: "Activo"},{value: 0, text: "Desactivado"}],
          "initial": initials && initials.usr_estado ? initials.usr_estado.value : ""
        },
        {
          "type": "none"
        },
        {
          "placeholder": "Por fecha",
          "type": "date-range",
          "field": "created_at",
          "initial": dates ? dates : null
        }
      ],
      buttons: [
        {
          "text": "Editar",
          "event": "editar-usuario",
        },
        {
          "text": "Desactivar",
          "event": "borrar-usuario",
          "condition": {
            "text": "Activar",
            "event":"activar-usuario",
            "value": ["isActive", "false"]
          }
        }
      ],
    }
  },
  data() {
    return {
      showModal: false,
      userInModal: null,
      rows: [
      ],
    }
  },
  computed: {
    sucursales() {
      let sucursales = JSON.parse(JSON.stringify(this.$store.getters["sucursal/sucursales"]))
      sucursales = sucursales.map(sucursal => {
        return {text: sucursal.suc_nombre,value: sucursal.suc_id}
      })
      return sucursales
    },
    ...mapGetters("user", ["error", "meta", "links"])
  },
  methods: {
    editar(value) {
      this.$router.push(`/usuarios/editar/${value.id}`)
    },
    borrar() {
      this.showModal = false
      UserService.disableUser(this.userInModal.id)
      .then((response) => {
        this.emitter.emit("notification-set", {
          type: 'success',
          title: response.data.title,
          text: response.data.message,
          time: 0,
          group: 'notifications',
        })
        this.$store.dispatch("user/getUsers", parseInt(this.$route.params.page) || 1)
      })
      .catch((error) => {
        console.log(error)
      })
    },
    activar() {
      this.showModal = false
      UserService.enableUser(this.userInModal.id)
      .then((response) => {
        this.emitter.emit("notification-set", {
          type: 'success',
          title: response.data.title,
          text: response.data.message,
          time: 0,
          group: 'notifications',
        })
        this.$store.dispatch("user/getUsers", parseInt(this.$route.params.page) || 1)
      })
      .catch((error) => {
        console.log(error)
      })
    },
    showUserModal(value) {
      this.userInModal = value
      this.showModal = true
      
    },
    setDates(dates) {
      console.log(dates)
      store.dispatch("user/setDates", dates)
      store.dispatch("user/getUsers", 1)
    },
    setFilters(filters) {
      store.dispatch("user/setFilters", filters)
      store.dispatch("user/getUsers", 1)
    }
  },
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1
    if (!store.getters["sucursal/sucursales"]) {
      store.dispatch("sucursal/getSucursales")
    }
    store.dispatch("user/getUsers", currentPage).then(() => {
      to.params.page = currentPage
      next()
    })
  },
  components: {
    ListTable,
    Pagination,
    Modal
  }
}
</script>